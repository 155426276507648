import React, { useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress, Fade, Grid, makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import AddAlert from '@material-ui/icons/AddAlert'
import LoginImage from 'assets/img/LoginImage.png'
import { blackColor, hexToRgb } from 'assets/jss/material-dashboard-pro-react.js'
import loginStyles from 'assets/jss/material-dashboard-pro-react/views/loginViewStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody'
import CardFooter from 'components/Card/CardFooter'
import CardHeader from 'components/Card/CardHeader'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { Spinner } from 'components/Loader/Spinner'
import Snackbar from 'components/Snackbar/Snackbar.js'
import { useCreateAccessCode } from 'hooks/useDataAssurePii'
import jwtDecode from 'jwt-decode'
import queryString from 'query-string'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { BankAccountOwnershipProvider, piiValidationUpdateOptions } from 'utils/Constants'
import { decodeBase64, hideLoading, showLoading } from 'utils/functions'
import * as yup from 'yup'
import { getPiiConfigurations, getPiiValidation, savePiiAgreementTerms, validateAccessCode } from '../../services/apiPII'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  inputLabel: {
    paddingBottom: '10px ',
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.40)',
    fontSize: '14px'
  },
  formField: {
    width: '95%',
    paddingBottom: '15px'
  }
}))

const useLoginStyles = makeStyles(loginStyles)

const loginObject = {
  'Access Code': ''
}

const schema = yup.object().shape({
  'Access Code': yup
    .string()
    .min(1)
    .required()
})

export default function DataAccessLogin(props) {
  // using history to push to data form
  const history = useHistory()
  const params = queryString.parse(props.location.search)
  const classes = useStyles()
  const loginClasses = useLoginStyles()
  const [showSpinner, setShowSpinner] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [loginForm, setLoginForm] = useState(loginObject)
  const [showAccesscode, setShowAccessCode] = useState(false)
  const [clientId, setClientId] = useState('')
  const [environmentId, setEnvironmentId] = useState('')
  const [env, setEnv] = useState('')
  const [email, setEmail] = useState('')
  const [bistroPiiTransaction, setBistroPiiTransaction] = useState('')
  const [formId, setFormId] = useState('')
  const [isValidToken, setIsValidToken] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [createError, setCreateError] = useState(false)
  const [urlToken, setUrlToken] = useState('')
  const { mutate: createAccessCode, isError, error } = useCreateAccessCode()
  const [isUpdate, setIsFormUpdate] = useState(false)
  const [sectionConfigs, setSectionConfigs] = useState(undefined)
  const [sectionToUpdate, setSectionToUpdate] = useState({
    supplierControl: true,
    sections: new Map([['bank', true], ['tax', true], ['attachments', true]])
  })
  const {
    handleSubmit,
    control,
    formState: { errors },
    formState,
    reset
  } = useForm({
    mode: 'all',
    defaultValues: useMemo(() => loginForm, [loginForm]),
    resolver: yupResolver(schema),
    shouldUnregister: false
  })

  const [cardAnimaton, setCardAnimation] = useState('cardHidden')
  const [isWaiting, setIsWaiting] = useState(false)
  const [piiValidation, setPiiValidation] = useState(undefined)

  setTimeout(function() {
    setCardAnimation('')
  }, 700)
  const MINUTE_MS = 600000

  useEffect(() => {
    const validateAccessToken = async () => {
      try {
        setShowSpinner(true)
        const accessToken = params.custom
        const tokenPayload = jwtDecode(accessToken)
        console.log('tokenPayload', { context: tokenPayload })

        localStorage.setItem('pii.urlToken', accessToken)
        setUrlToken(accessToken)
        if (isTokenExpired(tokenPayload.exp)) {
          setErrorMessage('Token already expired')
          setCreateError(true)
          setIsValidToken(false)
          return
        }

        const client = decodeBase64(tokenPayload.client)
        const environment = decodeBase64(tokenPayload.environment)
        const validationId = decodeBase64(tokenPayload.s)

        const piiValidationResponse = await getPiiValidation(client, environment, validationId)
        if (!piiValidationResponse.success) {
          setErrorMessage(`Could not get pii validation ---> ${piiValidationResponse.message}`)
          setCreateError(true)
          setIsValidToken(false)
          return
        }

        setPiiValidation(piiValidationResponse.data)
        const piiValidation = piiValidationResponse.data

        const lastEmailHistoryWithAccessToken = piiValidation.emailHistory
          .filter(eh => Boolean(eh.accessToken))
          .sort((a, b) => {
            return new Date(b.processingDate) - new Date(a.processingDate)
          })
          .shift()

        if (lastEmailHistoryWithAccessToken.accessToken !== accessToken) {
          setErrorMessage(`This token is not the most recent one.`)
          setCreateError(true)
          setIsValidToken(false)
          return
        }

        setIsValidToken(true)
        //Token Data
        //Email
        let email = Buffer.from(tokenPayload.e, 'base64').toString()
        setEmail(email)
        //HashKey
        setClientId(client)
        setEnvironmentId(environment)
        //validation Id
        setEnv(validationId)
        //Bistro Transaction Id
        if (tokenPayload.bpt) {
          setBistroPiiTransaction(decodeBase64(tokenPayload.bpt))
        }
        let updateFlag = tokenPayload.u
        if (updateFlag) {
          setIsFormUpdate(true)
        }
        setFormId(tokenPayload.formId)
        /**
         * TEST
         */
        // setEmail('rodolfo@relishiq.com')
        // setAgreementTerm('false')
        // setIsValidToken(true)
        // setIsFormUpdate(true)

        //TEST CUSTOM TOKEN
        // if (params.custom) {
        //   let tokenPayload2 = jwtDecode(params.custom)
        //   console.log('custom Token', { context: tokenPayload2 })
        // }
        const interval = setInterval(() => {
          console.log('reset')
          setLoginForm(loginObject)
          reset(loginForm)
          setSectionToUpdate({
            supplierControl: true,
            sections: new Map([['bank', true], ['tax', true], ['attachments', true]])
          })
          setShowAccessCode(false)
        }, MINUTE_MS)

        setShowSpinner(false)

        return () => clearInterval(interval)
      } catch (error) {
        setCreateError(true)
        setErrorMessage(error.message)
        setShowSpinner(false)
      }
    }

    validateAccessToken()
  }, [loginForm, params.custom, reset])

  function handleLoginButtonClick() {
    if (!showAccesscode) {
      setIsWaiting(true)
      //request to create access token
      createAccessCode(
        {
          urlToken,
          isUpdate
        },
        {
          onSuccess: async data => {
            if (data.status === 200) {
              setSuccessMessage(data.data.message)
              setShowSuccessMessage(true)
            }

            console.log('access token sent')
            console.log(data)
            console.log('Get PII Section configurations')
            
            if (piiValidation.processVersionChanged || piiValidation.processVersion !== 'V1') {
              setSectionToUpdate({
                supplierControl: false,
                sections: new Map([['bank', true], ['tax', true], ['attachments', true]])
              })
            } else {
              //Get PII Section Configurations
              let piiConfigs = await getPiiConfigurations(clientId, environmentId, urlToken)
              console.log('PII Section Configurations', piiConfigs)

              if (piiConfigs.data) {
                setSectionConfigs({
                  bankSectionEnabled: piiConfigs.data.sectionConfigurations.bankSectionEnabled,
                  taxSectionEnabled: piiConfigs.data.sectionConfigurations.taxSectionEnabled,
                  attachmentSectionEnabled: piiConfigs.data.sectionConfigurations.attachmentSectionEnabled
                })

                if (piiConfigs.data.piiFormUpdate) {
                  const { supplierControl, bankSection, taxSection, additionalAttachmentSection } = piiConfigs.data.piiFormUpdate
                  setSectionToUpdate({
                    supplierControl: supplierControl,
                    sections: new Map([
                      ['bank', supplierControl ? false : bankSection],
                      ['tax', supplierControl ? false : taxSection],
                      ['attachments', supplierControl ? false : additionalAttachmentSection]
                    ])
                  })
                }
              }
            }

            setShowAccessCode(true)
            setIsWaiting(false)
          },
          onError: () => {
            setIsWaiting(false)
          }
        }
      )
    }
  }

  const isTokenExpired = exp => {
    if (exp) {
      const currentTime = new Date().getTime() / 1000
      return currentTime > exp
    }
    return false
  }

  function validateIsError(field) {
    if (errors[field]) {
      return true
    }
    return false
  }

  async function setRedirectVariables(validateAccessCodeResponse){
    //Get Update Selected Sections
    const updateValues = []
    if (Array.from(sectionToUpdate.sections.values()).every(i => i)) {
      updateValues.push(piiValidationUpdateOptions.all)
    } else {
      if (sectionToUpdate.sections.get('bank')) {
        updateValues.push(piiValidationUpdateOptions.bank)
      }
      if (sectionToUpdate.sections.get('tax')) {
        updateValues.push(piiValidationUpdateOptions.tin)
      }
      if (sectionToUpdate.sections.get('attachments')) {
        updateValues.push(piiValidationUpdateOptions.attachment)
      }
    }

    console.log('validate access token success')
    //set values
    props.setClientId(clientId)
    props.setEnvironmentId(environmentId)
    props.setValidationId(env)
    props.setEmail(email)
    props.setBistroPiiTransaction(bistroPiiTransaction)
    props.setSupplierId(validateAccessCodeResponse.data.supplierId)
    props.setSupplierName(validateAccessCodeResponse.data.supplierName)
    if (validateAccessCodeResponse.data.dbaName) {
      props.setDBAName(validateAccessCodeResponse.data.dbaName)
      localStorage.setItem('pii.setDBAName', validateAccessCodeResponse.data.dbaName)
    }
    props.setIsFormUpdate(isUpdate.toString())
    props.setUrlToken(urlToken)
    props.setFormUpdateValidation(updateValues)
    //local storage
    localStorage.setItem('pii.clientId', clientId)
    localStorage.setItem('pii.environmentId', environmentId)
    localStorage.setItem('pii.validationId', env)
    localStorage.setItem('pii.email', email)
    localStorage.setItem('pii.bistroPiiTransaction', bistroPiiTransaction)
    localStorage.setItem('pii.supplierId', validateAccessCodeResponse.data.supplierId)
    localStorage.setItem('pii.setSupplierName', validateAccessCodeResponse.data.supplierName)
    localStorage.setItem('pii.isFormUpdate', isUpdate)
    localStorage.setItem('pii.urlToken', urlToken)
    localStorage.setItem('pii.formUpdateValidation', JSON.stringify(updateValues))
    //Get PII Configurations
    console.log('getting form conf...')
    let piiCustomFields = {
      customFields: {}
    }
    
    const piiConfigurations = validateAccessCodeResponse.data.configurations

    if (validateAccessCodeResponse.data.giactConfigurations) {
      props.setGiactConfigurations(validateAccessCodeResponse.data.giactConfigurations)
      localStorage.setItem('giactConfigurations', JSON.stringify(validateAccessCodeResponse.data.giactConfigurations))
    }

    if (validateAccessCodeResponse.data.bankAccountOwnershipConfigurations) {
      props.setBankAccountOwnershipConfigurations(validateAccessCodeResponse.data.bankAccountOwnershipConfigurations)
      localStorage.setItem('bankAccountOwnershipConfigurations', JSON.stringify(validateAccessCodeResponse.data.bankAccountOwnershipConfigurations))
    }

    if (piiConfigurations) {
      console.log('configurations received', { context: piiConfigurations })
      //Get Form Configurations
      if (piiConfigurations.customFields) {
        piiCustomFields.customFields = piiConfigurations.customFields
      }
      props.setFormconfigurations(piiCustomFields)
      localStorage.setItem('pii.formConfigurations', JSON.stringify(piiCustomFields))
      
      //Boolean Start With W9 Upload Form View
      props.setStartWithW9UploadForm(piiConfigurations.startWithW9UploadForm.toString())
      localStorage.setItem('pii.startWithW9UploadForm', piiConfigurations.startWithW9UploadForm.toString())

      //Boolean Always Allow Tin
      props.setAlwaysAllowTin(piiConfigurations.alwaysAllowTin.toString())
      localStorage.setItem('pii.alwaysAllowTin', piiConfigurations.alwaysAllowTin.toString())
      localStorage.setItem('piiConf', JSON.stringify(piiConfigurations.piiValidation))
      //Additional Files Configurations

      // allow additional files instructions
      props.setAdditionalFileInstructions(piiConfigurations.additionalFileInstructions.toString())
      localStorage.setItem('pii.additionalFileInstructions', piiConfigurations.additionalFileInstructions.toString())

      // allow additional files max files
      props.setMaxAdditionalFiles(piiConfigurations.maxAdditionalFiles)
      localStorage.setItem('pii.maxAdditionalFiles', piiConfigurations.maxAdditionalFiles)

      // allow additional files min files
      props.setMinAdditionalFiles(piiConfigurations.minAdditionalFiles || 0)
      localStorage.setItem('pii.minAdditionalFiles', piiConfigurations.minAdditionalFiles || 0)

      props.setUploadBankDocumentationEnabled(piiConfigurations.uploadBankDocumentationEnabled ?? false)
      localStorage.setItem('pii.uploadBankDocumentationEnabled', piiConfigurations.uploadBankDocumentationEnabled ?? false)

      props.setUploadBankDocumentationEnabled(piiConfigurations.uploadTaxDocumentationEnabled ?? false)
      localStorage.setItem('pii.uploadTaxDocumentationEnabled', piiConfigurations.uploadTaxDocumentationEnabled ?? false)

      props.setRequireBankAccountHolder(piiConfigurations.requireBankAccountHolder ?? false)
      localStorage.setItem('pii.requireBankAccountHolder', piiConfigurations.requireBankAccountHolder ?? false)

      //Section Configurations
      if (piiConfigurations.sectionConfigurations) {
        props.setSectionConfigurations(piiConfigurations.sectionConfigurations)
        localStorage.setItem('pii.sectionConfigurations', piiConfigurations.sectionConfigurations)

        // Bank section enabled
        if (piiConfigurations.bankSectionEnabled) {
          props.setBankSectionEnabled(piiConfigurations.bankSectionEnabled.toString())
          localStorage.setItem('pii.bankSectionEnabled', piiConfigurations.bankSectionEnabled)
        } else {
          props.setBankSectionEnabled(false)
          localStorage.setItem('pii.bankSectionEnabled', false)
        }
        // Bank Account Ownership Validation
        props.setBankAccountOwnershipValidationEnabled(piiConfigurations.bankAccountOwnershipValidationEnabled?.toString() ?? 'false')
        localStorage.setItem('pii.bankAccountOwnershipValidationEnabled', piiConfigurations.bankAccountOwnershipValidationEnabled ?? false)

        props.setBankAccountOwnershipValidationProvider(
          piiConfigurations.bankAccountOwnershipValidationProvider ?? BankAccountOwnershipProvider.DATA_ASSURE
        )
        localStorage.setItem(
          'pii.bankAccountOwnershipValidationProvider',
          piiConfigurations.bankAccountOwnershipValidationProvider ?? BankAccountOwnershipProvider.DATA_ASSURE
        )

        // Tax section enabled
        if (piiConfigurations.taxSectionEnabled) {
          props.setTaxSectionEnabled(piiConfigurations.taxSectionEnabled.toString())
          localStorage.setItem('pii.taxSectionEnabled', piiConfigurations.taxSectionEnabled)
        } else {
          props.setTaxSectionEnabled(false)
          localStorage.setItem('pii.taxSectionEnabled', false)
        }
        // Attachment section enabled
        if (piiConfigurations.attachmentSectionEnabled) {
          props.setAttachmentSectionEnabled(piiConfigurations.attachmentSectionEnabled.toString())
          localStorage.setItem('pii.attachmentSectionEnabled', piiConfigurations.attachmentSectionEnabled)
        } else {
          props.setAttachmentSectionEnabled(false)
          localStorage.setItem('pii.attachmentSectionEnabled', false)
        }
      }

      //Required or not bank and tax sections
      if (piiConfigurations.taxValidationRequired !== undefined) {
        props.setTaxValidationRequired(piiConfigurations.taxValidationRequired)
        localStorage.setItem('pii.taxValidationRequired', piiConfigurations.taxValidationRequired)
      }

      if (piiConfigurations.bankValidationRequired !== undefined) {
        props.setBankValidationRequired(piiConfigurations.bankValidationRequired)
        localStorage.setItem('pii.bankValidationRequired', piiConfigurations.bankValidationRequired)
      }

      //Mandatory Fields Configurations
      if (piiConfigurations.mandatoryFieldsConfiguration) {
        props.setMandatoryFieldsConfiguration(piiConfigurations.mandatoryFieldsConfiguration)
        localStorage.setItem('pii.mandatoryFieldsConfiguration', piiConfigurations.mandatoryFieldsConfiguration)

        // Account Number Field
        if (piiConfigurations.accountTypeField !== undefined) {
          props.setAccountTypeField(piiConfigurations.accountTypeField)
          localStorage.setItem('pii.accountTypeField', piiConfigurations.accountTypeField)
        }

        // Account Number Field
        if (piiConfigurations.accountNumberField !== undefined) {
          props.setAccountNumberField(piiConfigurations.accountNumberField)
          localStorage.setItem('pii.accountNumberField', piiConfigurations.accountNumberField)
        }
        // IBAN Number Field
        if (piiConfigurations.ibanNumberField !== undefined) {
          props.setIbanNumberField(piiConfigurations.ibanNumberField)
          localStorage.setItem('pii.ibanNumberField', piiConfigurations.ibanNumberField)
        }
        // Routing Number Field
        if (piiConfigurations.routingNumberField !== undefined) {
          props.setRoutingNumberField(piiConfigurations.routingNumberField)
          localStorage.setItem('pii.routingNumberField', piiConfigurations.routingNumberField)
        }
        //Initial Bank Country Default Value
        if (piiConfigurations.initialBankCountry !== undefined) {
          props.setInitialBankCountry(piiConfigurations.initialBankCountry)
          localStorage.setItem('pii.initialBankCountry', piiConfigurations.initialBankCountry)
        }
        //isBankUploadDocumentationRequired Default Value
        if (piiConfigurations.isBankUploadDocumentationRequired !== undefined) {
          props.setIsBankUploadDocumentationRequired(piiConfigurations.isBankUploadDocumentationRequired)
          localStorage.setItem('pii.isBankUploadDocumentationRequired', piiConfigurations.isBankUploadDocumentationRequired)
        }
        // SWIFT Code Field
        if (piiConfigurations.swiftCodeField !== undefined) {
          props.setSwiftCodeField(piiConfigurations.swiftCodeField)
          localStorage.setItem('pii.swiftCodeField', piiConfigurations.swiftCodeField)
        }
        // SSN Field
        if (piiConfigurations.ssnField !== undefined) {
          props.setSSNField(piiConfigurations.ssnField)
          localStorage.setItem('pii.ssnField', piiConfigurations.ssnField)
        }
        // EIN Field
        if (piiConfigurations.einField !== undefined) {
          props.setEINField(piiConfigurations.einField)
          localStorage.setItem('pii.einField', piiConfigurations.einField)
        }
      }
      // useTINForNonUSPII Paramm
      if (piiConfigurations.useTINForNonUSPII) {
        props.setUseTINForNonUSPII(piiConfigurations.useTINForNonUSPII)
        localStorage.setItem('pii.useTINForNonUSPII', piiConfigurations.useTINForNonUSPII)
      } else {
        props.setUseTINForNonUSPII(undefined)
        localStorage.removeItem('pii.useTINForNonUSPII')
      }
      // Include Bank Account Type
      if (piiConfigurations.includeBankAccountType !== undefined) {
        props.setIncludeBankAccountType(piiConfigurations.includeBankAccountType)
        localStorage.setItem('pii.includeBankAccountType', piiConfigurations.includeBankAccountType)
      } else {
        props.setIncludeBankAccountType(undefined)
        localStorage.removeItem('pii.includeBankAccountType')
      }

      if (piiConfigurations.maxAttemptsTax) {
        props.setMaxAttemptsTax(piiConfigurations.maxAttemptsTax)
        localStorage.setItem('pii.maxAttemptsTax', piiConfigurations.maxAttemptsTax)
      }

      if (piiConfigurations.maxAttemptsBank) {
        props.setMaxAttemptsBank(piiConfigurations.maxAttemptsBank)
        localStorage.setItem('pii.maxAttemptsBank', piiConfigurations.maxAttemptsBank)
      }

      //Parameter to allow "other" as an option to bypass tax validation
      if (piiConfigurations.allowOtherNonValidatedTaxID !== undefined) {
        props.setAllowOtherNonValidatedTaxID(piiConfigurations.allowOtherNonValidatedTaxID)
        localStorage.setItem('pii.allowOtherNonValidatedTaxID', piiConfigurations.allowOtherNonValidatedTaxID)
      } else {
        props.setAllowOtherNonValidatedTaxID(true)
        localStorage.setItem('pii.allowOtherNonValidatedTaxID', true)
      }

      if (piiConfigurations.performNameComparison !== undefined) {
        //Is perform name comparison active?
        props.setPerformNameComparison(piiConfigurations.performNameComparison)
        localStorage.setItem('pii.performNameComparison', piiConfigurations.performNameComparison)
        //Name Comparison Thresholds
        props.setNameComparisonPassThreshold(piiConfigurations.nameComparisonPassThreshold)
        localStorage.setItem('pii.nameComparisonPassThreshold', piiConfigurations.nameComparisonPassThreshold)
        props.setNameComparisonWarningThreshold(piiConfigurations.nameComparisonWarningThreshold)
        localStorage.setItem('pii.nameComparisonWarningThreshold', piiConfigurations.nameComparisonWarningThreshold)
      } else {
        //By default name comparison is deactivate
        props.setPerformNameComparison(false)
        localStorage.setItem('pii.performNameComparison', false)
      }
    }
    
    console.log('setting relish body message...')
    //agreement already filled up
    console.log('setting hideLoading')
    hideLoading()
    setIsWaiting(false)
  }

  async function redirectToForm(){
    switch(piiValidation.processVersion){
      case 'FORM_BUILDER':
        window.location.href = `${process.env.REACT_APP_WEB_RELISH_APP_CENTER_URL}/platform/public/pii-supplier-form/?accessToken=${urlToken}&formId=${formId}`;
        break
      default: 
        history.push('/pii/supplier/form')
        break
    }
  }

  async function onSubmitHook(data) {
    console.log('validating...')
    showLoading()
    setIsWaiting(true)
    //pii form input
    const response = await validateAccessCode(data['Access Code'], urlToken)
    if (response.success) {
      setRedirectVariables(response)
      if(response.data?.agreement === 'false'){
        let relishMessage = {
          success: false,
          data: {}
        }

        //Relish Message
        if (response.data.configurations?.relishBodyMessage) {
          relishMessage.success = true
          relishMessage.data.message = response.data.configurations?.relishBodyMessage
        }

        console.log('redirect to relish consent')
        console.log('setting hideLoading')
        hideLoading()
        setIsWaiting(false)
        props.setAppId('slp-pii')
        props.setTypeId('consent')
        // Relish Consent Body
        props.setMessage(relishMessage)
        props.setCallBackFunction({
          onclick: (client, environment, validationId, version, token) => {
            debugger
            acceptAgreementCallFunction(client, environment, validationId, version, token)
          }
        })
        history.push('/pii/supplier/relishMessage')
      } else {
        await redirectToForm()
      }
    } else {
      console.log('not validated')
      hideLoading()
      setIsWaiting(false)
      //local storage
      localStorage.removeItem('pii.clientId')
      localStorage.removeItem('pii.environmentId')
      localStorage.removeItem('pii.validationId')
      localStorage.removeItem('pii.email')
      localStorage.removeItem('pii.bistroPiiTransaction')
      localStorage.removeItem('pii.supplierId')
      localStorage.removeItem('pii.setSupplierName')
      localStorage.removeItem('pii.setDBAName')
      localStorage.removeItem('pii.isFormUpdate')
      localStorage.removeItem('pii.urlToken')
      localStorage.removeItem('pii.formUpdateValidation')
      setCreateError(true)
      setErrorMessage(response.message)
    }
  }

  async function acceptAgreementCallFunction(client, environment, validationId, agreementVersion, token) {
    const response = await savePiiAgreementTerms(client, environment, validationId, agreementVersion, token)
    if (response && response.data.success) {
      await redirectToForm()
    }
  }

  useEffect(() => {
    if (isError) {
      setCreateError(true)
    }
  }, [isError])

  const childrenOptions = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      {sectionConfigs?.bankSectionEnabled && (
        <FormControlLabel
          label="Bank Validation"
          control={
            <Checkbox
              checked={sectionToUpdate.sections.get('bank')}
              onChange={event => {
                setSectionToUpdate(prevState => ({
                  ...prevState,
                  sections: new Map(prevState.sections).set('bank', event.target.checked)
                }))
              }}
            />
          }
        />
      )}
      {sectionConfigs?.taxSectionEnabled && (
        <FormControlLabel
          label="Tin Validation"
          control={
            <Checkbox
              checked={sectionToUpdate.sections.get('tax')}
              onChange={event => {
                setSectionToUpdate(prevState => ({
                  ...prevState,
                  sections: new Map(prevState.sections).set('tax', event.target.checked)
                }))
              }}
            />
          }
        />
      )}
      {sectionConfigs?.attachmentSectionEnabled && (
        <FormControlLabel
          label="Additional Attachments"
          control={
            <Checkbox
              checked={sectionToUpdate.sections.get('attachments')}
              onChange={event => {
                setSectionToUpdate(prevState => ({
                  ...prevState,
                  sections: new Map(prevState.sections).set('attachments', event.target.checked)
                }))
              }}
            />
          }
        />
      )}
    </Box>
  )
  localStorage.setItem('isPii', 'true')
  return (
    <Fade in={true} timeout={250}>
      <div className={loginClasses.container} style={{ paddingTop: '5%', paddingBottom: '5%' }}>
        {showSpinner ? <Spinner /> : ''}
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={handleSubmit(onSubmitHook)}
             onKeyDown={e => {
              if (e.key === 'Enter' && showAccesscode) {
                e.preventDefault()
              }
            }}
            >
              <Card login className={loginClasses[cardAnimaton]}>
                <CardHeader className={`${loginClasses.cardHeader} ${loginClasses.textCenter}`} color="primary">
                  <h2 className={loginClasses.cardTitle}>Relish</h2>
                </CardHeader>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <img width="100%" src={LoginImage} alt="logo" className={loginClasses.logotypeImage} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <h5 style={{ textAlign: 'center' }}>
                        {showAccesscode
                          ? 'An e-mail has been sent to you with an access code. Please enter it below'
                          : `Welcome to the supplier portal. To continue, click on the Next button`}
                      </h5>
                    </GridItem>
                    {showAccesscode ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              InputLabelProps={{
                                className: classes.inputLabel
                              }}
                              className={classes.formField}
                              label="Access Code*"
                              error={validateIsError('Access Code')}
                              helperText={errors['Access Code'] ? errors['Access Code'].message : ''}
                              {...field}
                            />
                          )}
                          name="Access Code"
                          control={control}
                        />
                      </GridItem>
                    ) : (
                      ''
                    )}
                    {showAccesscode && formState.isValid && isValidToken && isUpdate ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl component="fieldset" style={{ display: sectionToUpdate.supplierControl ? 'auto' : 'none' }}>
                          <FormLabel className={classes.labelHorizontal}>Choose sections for update:</FormLabel>
                          <FormControlLabel
                            label="All"
                            control={
                              <Checkbox
                                checked={Array.from(sectionToUpdate.sections.values()).every(s => s)}
                                onChange={event => {
                                  setSectionToUpdate(prevState => ({
                                    ...prevState,
                                    sections: new Map(Array.from(prevState.sections.keys()).map(k => [k, event.target.checked]))
                                  }))
                                }}
                              />
                            }
                          />
                          {childrenOptions}
                        </FormControl>
                      </GridItem>
                    ) : (
                      ''
                    )}
                  </GridContainer>
                </CardBody>
                <CardFooter className={loginClasses.justifyContentCenter}>
                  {!isWaiting ? (
                    <Button
                      color="primary"
                      simple
                      size="lg"
                      block
                      onClick={handleLoginButtonClick}
                      type={showAccesscode ? 'submit' : undefined}
                      disabled={(() => {
                        if (showAccesscode) {
                          if (!formState.isValid) return true

                          if (formState.isValid && isValidToken && isUpdate && Array.from(sectionToUpdate.sections.values()).every(i => !i))
                            return true
                        } else {
                          if (!isValidToken) return true
                        }
                        return false
                      })()}
                    >
                      {showAccesscode ? 'Login' : 'Next'}
                    </Button>
                  ) : (
                    <Grid container className={classes.progressContainer}>
                      <div
                        style={{
                          position: 'relative',
                          zIndex: '1300',
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignContent: 'center',
                          backgroundColor: '#ffffff',
                          marginBottom: '25px'
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </div>
                    </Grid>
                  )}
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
        <Snackbar
          place="br"
          color="success"
          icon={AddAlert}
          message={successMessage}
          open={showSuccessMessage}
          closeNotification={() => {
            setSuccessMessage('')
            setShowSuccessMessage(false)
          }}
          close
        />
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={isError ? error.response?.data?.message || 'Something went wrong, try again later' : errorMessage ? errorMessage : ''}
          open={createError}
          closeNotification={() => {
            setCreateError(false)
            if (errorMessage) {
              setErrorMessage(undefined)
            }
          }}
          close
        />
      </div>
    </Fade>
  )
}
